<template>
  <div class='surveys-table'>
    <base-table
      :columns='columns'
      :data='linksCheckoutsDifferentPrices'
      :total-items='linkCheckoutsDifferentPricesMeta.total'
      :start-items='linkCheckoutsDifferentPricesMeta.skip + 1'
      :max-items='linkCheckoutsDifferentPricesMeta.limit'
      @set-page='handleChangeUpdateLogs'
    >
      <template v-slot:body-active='row'>
        {{ row.item.active ? 'SIM' : 'NãO' }}
      </template>

      <template v-slot:body-value='row'>
        {{ Number(row.item.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
      </template>

      <template v-slot:body-url='row'>
        <div class='api-key'>
          <a :href='row.item.url' target='_blank' :title='row.item.url'><span :id='row.item._id'>{{ row.item.url }}</span></a>
        </div>

        <!--<i
          class='fas fa-copy fa-lg'
          @click='copy(row.item._id)'
        />-->
      </template>

      <template v-slot:body-actions='row'>
        <i
          class='fas fa-pen fa-lg'
          @click='seeMoreUser(row.item._id)'
        />
        <i
          class='fas fa-trash fa-lg'
          @click="$emit('remove-linkCheckoutsDifferentPrices', row.item)"
        />
      </template>
    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'
import { mapActions } from 'vuex'

export default {
  name: 'LinkCheckoutsDifferentPricesTable',
  props: {
    linksCheckoutsDifferentPrices: { type: Array, require: true },
    linkCheckoutsDifferentPricesMeta: { type: Object, require: true }
  },
  async created() {
  },
  data() {
    return {
      logUpdate: {},
      openDialog: false,
      columns: [
        { key: 'url', value: 'Link' },
        { key: 'plan', value: 'Plano' },
        { key: 'code', value: 'Código' },
        { key: 'value', value: 'Valor' },
        { key: 'active', value: 'Ativo' },
        { key: 'createdAt', value: 'Data cadastro' },
        { key: 'actions', value: 'Ações' }
      ]
    }
  },
  components: {
  },
  methods: {
    ...mapActions('linkCheckoutsDifferentPrices', {
      handleGetLinksCheckoutsDifferentPrices: 'handleGetLinksCheckoutsDifferentPrices'
    }),
    async copy(id) {
      try {
        const content = document.getElementById(id).innerHTML
        if (navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(content)
            .then(() => {
              this.$bvToast.toast('Chave copiada para área de transferência', {
                title: 'Aviso',
                variant: 'success',
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000
              })
            })
            .catch((err) => {
              this.$bvToast.toast('Não foi possivél copiar a chave para área de transferência', {
                title: 'Aviso',
                variant: 'danger',
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000
              })
            })
        } else {
          /*let textArea = document.createElement('textarea')
          textArea.value = content
          textArea.style.position = 'fixed'
          textArea.style.left = '-999999px'
          textArea.style.top = '-999999px'
          document.body.appendChild(textArea)
          textArea.focus()
          textArea.select()
          document.execCommand('copy')
          textArea.remove()
          this.$bvToast.toast('Chave copiada para área de transferência', {
            title: 'Aviso',
            variant: 'success',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000
          })*/
        }
      } catch (e) {
        console.log(e)
      }
    },

    async showToastResponse(message) {
      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      await this.handleGetLinksCheckoutsDifferentPrices()
      this.close()
    },

    async seeMoreUser(_id) {
      this.$emit('update-linkCheckoutsDifferentPrices', _id)
    },

    formatDate(date) {
      return format(date, 'dd/MM/yyyy')
    },

    handleChangeUpdateLogs(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.linkCheckoutsDifferentPricesMeta.limit)
      }
      this.$emit('change-linkCheckoutsDifferentPrices', params)
    },
  }
}
</script>

<style lang='scss' scoped>
.api-key {
  /*max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;*/
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
