<template>
  <div class='create-promocode'>
    <base-input-text
      v-model='v$.linkCheckoutsDifferentPrices.code.$model'
      label='Código'
      placeholder='Código'
    />

    <div class='d-flex align-items-end w-100 justify-content-between'>
      <div class='w-50'>
        <label class='label'>Preço</label>
        <money
          class='form-control price'
          v-model="v$.linkCheckoutsDifferentPrices.price.$model"
          v-bind="money"
          :disabled="linkCheckoutsDifferentPrices.plan === 'MO1HO'"
        >

        </money>
      </div>
      <div class='w-50 d-flex flex-row justify-content-end align-items-end ml-3'>
        <base-input-select
          class='w-50'
          v-model="v$.linkCheckoutsDifferentPrices.plan.$model"
          label="Plano"
          @change='setPrice'
          placeholder="Selecione o plano"
          :options="plans"
          _key="key"
          _value="value"
        />

        <base-input-checkox
          class='mt-3 ml-3 w-50'
          v-model="v$.linkCheckoutsDifferentPrices.active.$model"
          label="Ativo"
        />
      </div>
    </div>
    <div  class='d-flex'>
      <base-button
        danger
        class='w-50 '
        @click="close()"
      >
        Cancelar
      </base-button>

      <base-button
        @click='handleSubmit'
        :disabled='v$.$invalid'
        class='w-50 space'
      >
        {{ linksCheckoutsDifferentPricesId ? 'Atualizar' : 'Cadastrar' }}
      </base-button>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/validators'
import { email } from '@vuelidate/validators'
export default {
  name: 'AddLinkCheckoutsDifferentPrices',
  setup() {
    return { v$: useVuelidate() }
  },
  props:{
    linksCheckoutsDifferentPricesId: { type: String, default: '' },
  },
  data() {
    return {
      errors: {
        client: ''
      },
      plans:[
        { key: "AN1HO" , value: "Plano anual" },
        { key: "MO1HO" , value: "Assinatura mensal" },
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: true
      }
    }
  },
  async created() {
    if (this.linksCheckoutsDifferentPricesId) {
      const response = await this.handleLinksCheckoutsDifferentPrices(this.linksCheckoutsDifferentPricesId)
      if (!response.data) {
        this.$bvToast.toast(response.message, {
          title: 'Aviso',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      }
    }else {
      await this.resetLinksCheckoutsDifferentPrices()
      this.linkCheckoutsDifferentPrices.active = true
    }
  },
  computed: {
    ...mapGetters('linkCheckoutsDifferentPrices', {
      linkCheckoutsDifferentPrices: 'getLinkCheckoutsDifferentPrices'
    })
  },
  validations() {
    return {
      linkCheckoutsDifferentPrices: {
        code: { required },
        price: { required },
        active: { required },
        plan: { required },
      }
    }
  },
  methods: {
    ...mapActions('linkCheckoutsDifferentPrices', {
      handleCreateLinkCheckoutsDifferentPrices: 'handleCreateLinkCheckoutsDifferentPrices',
      handleUpdateLinksCheckoutsDifferentPrices: 'handleUpdateLinksCheckoutsDifferentPrices',
      resetLinksCheckoutsDifferentPrices: 'resetLinksCheckoutsDifferentPrices',
      handleLinksCheckoutsDifferentPrices: 'handleLinksCheckoutsDifferentPrices',
    }),
    getError(value){
      if(value.$errors.length > 0){
        return value.$errors[0].$message
      }
      return ''
    },
    setPrice(){
      if(this.linkCheckoutsDifferentPrices.plan === 'MO1HO'){
        this.linkCheckoutsDifferentPrices.price = '247.00'
      }
    },
    close(){
      this.$emit("close")
    },
    async handleSubmit() {

      let response = '';
      console.log(this.linkCheckoutsDifferentPrices)

      if (this.linksCheckoutsDifferentPricesId) {
        response = await this.handleUpdateLinksCheckoutsDifferentPrices(this.linksCheckoutsDifferentPricesId)
      } else {
        response = await this.handleCreateLinkCheckoutsDifferentPrices()
      }

      let message = response.message
      if (response.errors) {
        message = response.errors
        this.$bvToast.toast(message, {
          title: 'Aviso',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      }
      this.$emit('show-toast-response', message);
      this.resetLinksCheckoutsDifferentPrices()
    },
    async remove(id) {
      const response = await this.removePromocode(id)

      let message = ''
      let variant = ''

      if (response.message) {
        message = response.message
        variant = 'success'
        this.handleClearPromocode()
      }
      if (response.errors) {
        message = response.errors
        variant = 'danger'
      }

      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
    }
  }

}
</script>

<style lang='scss' scoped>
.price{
  height: 48px;
}
.label {
  font-size: 18px;
  font-weight: 700;
}
.create-promocode {
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    display: inline-block;
  }

  .space {
    margin-left: 10px;
  }

  h4 {
    font-weight: bold;
    border-bottom: 2px solid var(--blue);
    color: var(--blue);
    padding-bottom: 4px;
  }
}
</style>
