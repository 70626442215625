<template>
  <div class='surveys-view'>
    <dialog-confirmation
      :description='message'
      :is-open='openDialog'
      @close='openDialog = false'
      @confirmed='deleteLinksCheckoutsDifferentPrices'
    />

    <div class='py-3' v-if='addKey'>
      <add-link-checkouts-different-prices
        :linksCheckoutsDifferentPricesId='linksCheckoutsDifferentPricesId'
        :readOnly='false' @close='close'
        @show-toast-response='showToastResponse' />
    </div>

    <div v-else class='py-3'>
      <div class='d-flex py-3 justify-content-between'>
        <div>
          <link-checkouts-different-prices-header @change-api-key="handleChangeLinksCheckoutsDifferentPrices"/>
        </div>

        <div class="search d-flex">
          <base-input-text
            v-model="filter"
            placeholder="Pesquisar"
            type="text"
          />
          <base-button
            :disabled="!filter"
            @click="filter = ''"
          >
            Limpar
          </base-button>
        </div>

        <base-button @click='show()' class='w-25'>
          <b>CADASTRAR LINK</b>
        </base-button>
      </div>

      <link-checkouts-different-prices-table
        :linksCheckoutsDifferentPrices='linksCheckoutsDifferentPrices'
        :linkCheckoutsDifferentPricesMeta='linkCheckoutsDifferentPricesMeta'
        @change-linkCheckoutsDifferentPrices='handleChangeLinksCheckoutsDifferentPrices'
        @remove-linkCheckoutsDifferentPrices='removeLinksCheckoutsDifferentPrices'
        @update-linkCheckoutsDifferentPrices='updateLinksCheckoutsDifferentPrices'
      />
      <p class='text-center' v-if='linksCheckoutsDifferentPrices.length < 1'>Nenhum item encontrado</p>
    </div>
  </div>
</template>

<script>
import LinkCheckoutsDifferentPricesTable from '@/components/LinkCheckoutsDifferentPrices/Table'
import LinkCheckoutsDifferentPricesHeader from '@/components/LinkCheckoutsDifferentPrices/Header'
import AddLinkCheckoutsDifferentPrices from '@/components/LinkCheckoutsDifferentPrices/AddLinkCheckoutsDifferentPrices'
import { mapActions, mapGetters } from 'vuex'
import DialogConfirmation from '@/components/DialogConfirmation.vue'
import _ from 'underscore'


export default {
  name: 'LinkCheckoutsDifferentPrices',
  data() {
    return {
      logUpdate: {},
      openDialog: false,
      openDialogModalSeeUpdateLogs: false,
      linkCheckoutsDifferentPriceRemove: '',
      partnerApiKey: '',
      addKey: false,
      linksCheckoutsDifferentPricesId: '',
      message: '',
      filter: ''
    }
  },
  components: {
    LinkCheckoutsDifferentPricesTable,
    LinkCheckoutsDifferentPricesHeader,
    AddLinkCheckoutsDifferentPrices,
    DialogConfirmation
  },
  computed: {
    ...mapGetters('linkCheckoutsDifferentPrices', {
      linksCheckoutsDifferentPrices: 'getLinksCheckoutsDifferentPrices',
      linkCheckoutsDifferentPricesMeta: 'getLinkCheckoutsDifferentPricesMetaMeta'
    })
  },
  watch: {
    filter: _.debounce(async function () {
      await this.handleChangeLinksCheckoutsDifferentPrices({ filter: this.filter });
    }, 1500),
  },
  methods: {
    ...mapActions('linkCheckoutsDifferentPrices', {
      handleGetLinksCheckoutsDifferentPrices: 'handleGetLinksCheckoutsDifferentPrices',
      resetLinksCheckoutsDifferentPrices: 'resetLinksCheckoutsDifferentPrices',
      handleDeleteLinksCheckoutsDifferentPrices: 'handleDeleteLinksCheckoutsDifferentPrices',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    async showToastResponse(message) {
      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      await this.handleGetLinksCheckoutsDifferentPrices()
      this.close()
    },
    show() {
      this.linksCheckoutsDifferentPricesId = ''
      this.addKey = true
    },
    close() {
      this.addKey = false
      this.resetLinksCheckoutsDifferentPrices()
    },
    removeLinksCheckoutsDifferentPrices(item) {
      this.linkCheckoutsDifferentPriceRemove = item
      this.openDialog = true
      this.message = 'Você está prestes a excluir o link. Tem certeza que deseja realizar esta ação?'
    },

    async updateLinksCheckoutsDifferentPrices(_id) {
      this.linksCheckoutsDifferentPricesId = _id
      this.addKey = true
    },
    async deleteLinksCheckoutsDifferentPrices() {
      const response = await this.handleDeleteLinksCheckoutsDifferentPrices(this.linkCheckoutsDifferentPriceRemove._id)
      let message = response.message
      let variant = 'success'

      if (response.errors) {
        message = response.errors
        variant = 'danger'
      }

      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      this.openDialog = false
      await this.handleGetLinksCheckoutsDifferentPrices({
        sortIn: 'desc'
      })
    },
    getUpdateLog(index) {
      this.handleGetUpdateLogByIndex(index)
      this.openDialog = true
    },
    async handleChangeLinksCheckoutsDifferentPrices(payload) {
      const params = {
        ...this.linkCheckoutsDifferentPricesMeta,
        ...payload
      }

      await this.handleGetLinksCheckoutsDifferentPrices(params)
    }
  },
  async created() {

    await this.handleGetLinksCheckoutsDifferentPrices({
      sortIn: 'desc'
    })

    const breadcrumb = {
      page: 'Links de checkout',
      subPage: {
        name: 'Links de checkout',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  }
}
</script>

<style lang='scss' scoped>
.none-sublinhado {
  text-decoration: none;
}

.surveys-view {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.search {
  display: flex;
  justify-content:flex-end;
  gap: 4px;

  /deep/ input {
    width: 256px;
  }
}
</style>
